<template>
    <div class="animated fadeIn">
        <b-card no-body>
            <b-card-header>
                <i class="icon-note"></i> Release Dana
                <div class="card-header-actions">
                    <a class="card-header-action" href="article" rel="noreferrer noopener">
                        <small class="text-muted">Back</small>
                    </a>
                </div>
            </b-card-header>
            <b-card-body>
                <div class="loading" v-if="isLoading == true">
                    <div class="sk-three-bounce">
                        <div class="sk-child sk-bounce1"></div>
                        <div class="sk-child sk-bounce2"></div>
                        <div class="sk-child sk-bounce3"></div>
                    </div>
                </div>
                <b-row>
                    <b-col sm="12">
                        <!--<h6>Simple Form</h6>-->
                        <b-form v-on:submit.prevent="onSubmit" novalidate>
                            <b-form-group id="emailInputGroup1"
                                            label="email"
                                            label-for="email">
                                <b-form-input id="email"
                                            type="email"
                                            v-model.lazy.trim="$v.form.email.$model"
                                            :state="chkState('email')"
                                            aria-describedby="emailFeedback"
                                            placeholder="Please Enter User Email"
                                            autofocus />
                                <b-form-invalid-feedback id="emailFeedback">
                                  <span v-if="!this.$v.form.email.required"> Email tidak boleh kosong!</span>
                                  <span v-if="!this.$v.form.email.email"> Email tidak valid</span>
                                </b-form-invalid-feedback>
                            </b-form-group>
                            <b-button type="submit" variant="primary">
                                Submit
                            </b-button>
                        </b-form>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
  import { validationMixin } from "vuelidate"
  import { required, email } from "vuelidate/lib/validators"
  export default {
    name: 'release.dana',
    computed: {
      formStr() { return JSON.stringify(this.form, null, 4) },
      isValid() { return !this.$v.form.$anyError },
      isDirty() { return this.$v.form.$anyDirty },
      invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
      form: {
        email: {
          required,
          email
        },
      }
    },
    data() {
      return {
        isLoading: false,
        form: {
          email: ''
        }
      }
    },
    methods: {
      onSubmit() {
        this.$v.$touch()
        if (!this.$v.$invalid) {
            this.isLoading = true
            this.$http.post(`release`, this.form).then(() => {
              this.isLoading = false
              this.$swal.fire(
                'Success!',
                'Dana Berhasil dikirim!',
                'success'
              )
            }).catch((error) => {
              if (error.response) {
                this.isLoading = false
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message,
                  'error'
                )
              }
            })
        }
      },
      chkState(val) {
        const field = this.$v.form[val]
        return !field.$dirty || !field.$invalid
      },
      findFirstError(component = this) {
        if (component.state === false) {
          if (component.$refs.input) {
            component.$refs.input.focus()
            return true
          }
          if (component.$refs.check) {
            component.$refs.check.focus()
            return true
          }
        }
        let focused = false
        component.$children.some((child) => {
          focused = this.findFirstError(child)
          return focused
        })

        return focused
      },
    },
  }
</script>

<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
.skus {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

#minutes__BV_label_ {
  visibility: hidden;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
